.bloc-content--404 {
    h2 {
        display: block;
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 12.5rem;
        text-align: center;

        &::after {
            display: none;
        }
    }

    p {
        font-size: $font-size--5;
        text-align: center;
    }
}

.search--404 {
    width: 970px;

    .search-container .searchInput {
        border-color: $color-gray !important;

        &:hover, &:focus {
            border-color: $color-second !important;
        }
    }
}

.grid-center-noGutter.container__buttons {
    margin: 30px 0;
}


//======================================================================================================
// Responsive
//======================================================================================================

// 640
@media screen and (max-width: $small) {

    .bloc-content--404 {
        h2 {
            font-size: 10rem;
        }
    }

}
