.scrollTopBtn {
    position: fixed;
    z-index: 100;
    bottom: 20px;
    right: 20px;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    border: none;
    margin: 0;
    padding: 0;
    opacity: 0;
    transform: translateY(100px);
    transition: transform $duration ease-in-out;

    &--show,
    &--show:focus  {
        opacity: 1;
        transform: translateY(0);
        background: $btn-second-bg;
        transition: all $duration ease-in-out;
    }

    svg {
        transition: none;

        path {
            fill: $btn-second-color;
            transition: fill $duration ease-in-out;
        }
    }

    &:hover, &:focus {
        background-color: $btn-second-bg--hover;

        svg {
            transition: none;

            path {
                fill: $btn-second-color--hover;
                transition: fill $duration ease-in-out;
            }
        }    
    }

    &::after {
        height: 100%;
    }
  }