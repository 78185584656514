.footer {
    display: flex;
    flex-direction: column;

    .container {
        display: flex;
    }

    &__title {
        margin: 0 0 5px;
        font-family: $font-family--heading;
        font-size: $font-size--3;
        font-weight: $font-weight--heading;
        color: $color-text;
    }

    &__subtitle {
        margin: 20px 0 5px 0;
        font-family: $font-family--heading;
        font-size: $font-size--4;
        font-weight: $font-weight-semibold;
        color: $color-text;
    }

    &__infos {
        padding: 50px 0;
        background-color: $color-main;
        color: $color-text;
        overflow: hidden;

        .container {
            display: flex;
            align-content: stretch;
            flex-wrap: wrap;
            position: relative;

            &::after {
                content: '';
                position: absolute;
                z-index: 0;
                left: 44px;
                bottom: -92px;
                width: 271px;
                height: 271px;
                background-image: url(/wp-content/themes/chanverrie/assets/src/images/sprite-images/decoration/filigrane_footer.svg);
                background-repeat: no-repeat;
                background-position: center;
            }
        }
    }

    &__mairie {
        position: relative;
        z-index: 1;
        width: 370px;
        margin-right: 100px;
    }

    &__coordonnees {
        position: relative;
        z-index: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;

        svg {
            width: 26px;
            height: 26px;
            margin-right: 5px;
        }
    }

    &__horaires {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
    }

    &__contact {
        position: relative;
        z-index: 1;
        display: flex;
        align-items: flex-end;
        margin-top: -35px;
    }

    &__button {
        margin-top: 30px;
        background-color: $color-white;
        border-color: $color-white;
        color: $color-text;
        padding: 12px 20px;
        margin-bottom: 0;

        svg {
            fill: $color-text;
            background-color: $color-main;
            margin-right: 10px;
            border-radius: $border-radius--round;
            transition: background-color $duration ease-in-out;
        }

        &::after {
            right: inherit;
            bottom: inherit;
            top: 15px;
            left: 22px;
            width: 20px;
            height: 20px;
            transform: scale(1);
            transition: all $duration ease-out;
            background-color: $color-dark;
        }

        &:hover, &:focus {
            svg {
                background-color: $color-second;
                border-color: $color-second;
                fill: $color-black;
                transition: all $duration ease-in-out;
            }

            &::after {
                top: -100%;
                left: -40px;
                height: 200px;
                width: 200px;
                transition: all $duration ease-out;
            }
        }
    }

    &__phone {
        position: relative;
        display: flex;
        align-items: center;
        width: fit-content;
        width: -moz-fit-content;
        width: -webkit-fit-content;
        margin-right: 30px;
        font-weight: $font-weight-bold;
        background-color: $color-dark;
        border-color: $color-dark;
        color: $color-white;

        svg {
            background-color: $color-white;
        }

        &::after {
            background-color: $color-white;
        }

        &:hover, &:focus {
            color: $color-text;
            background-color: $color-white;
            border-color: $color-white;

            svg {
                background-color: $color-second;
                fill: $color-black;
            }
            
        }
    }

    &__partner {
        margin-left: auto;
        margin-top: -35px;

        img {
            display: block;
            width: 93px;
            height: 93px;
            padding: 10px;
            background-color: $color-white;
            border: 1px solid $color-white;
            border-radius: $border-radius--round;
            transition: border-color $duration ease-in-out;
            box-sizing: content-box;
        }

        &:hover, &:focus {
            img {
                border-color: $color-dark;
                transition: border-color $duration ease-in-out;
            }
        }
    }

    &__menu {
        padding: 10px 0;
        background-color: $color-bg--neutral;

        &__list  {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            width: 100%;
        }

        &__item {

            &:last-child .footer__menu__link {
                &::after {
                    display: none;
                }
            }
        }

        &__link {
            position: relative;
            display: block;
            padding: 20px;
            color: $color-text;
            text-align: center;

            &:hover, &:focus {
                color: $color-text;
                text-decoration: underline;
            }

            &::after {
                content: '';
                position: absolute;
                top: 50%;
                right: 0;
                width: 1px;
                height: 25%;
                background-color: $color-dark;
                border-radius: 50px;
                transform: translateY(-50%);
                opacity: 1;
                transition: opacity $duration ease-in-out;
            }
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    .footer {

        &__button {
            margin-top: 25px;
        }

        &__coordonnees {
            width: 280px;
        }

        &__horaires {
            width: 480px;
        }
    }

}


// 960
@media screen and (max-width: $medium) {

    .footer {
        .container {
            position: relative;
        }

        &__coordonnees, 
        &__horaires {
            text-align: left;
            justify-content: flex-start;
            align-items: flex-start;
            width: 100%;
            position: relative;
            z-index: 1;
        }

        &__infos {
            .container {
                flex-direction: column;
                align-items: center;
                justify-content: center;
            }

            .container::after {
                left: 0;
                right: 0;
                bottom: -105px;
                width: auto;
                height: 385px;
                background-size: contain;
            }
        }

        &__mairie {
            width: 100%;
            margin-right: 0;

            &--1 {
                order: 1;
            }

            &--2 {
                order: 3;
            }
        }

        &__contact {
            flex-direction: column;
            align-items: flex-start;
            order: 2;
            width: 100%;
            margin-top: 10px;
            margin-bottom: 30px;
        }

        &__title {
            margin-bottom: 5px;
        }  
        
        &__partner {
            position: absolute;
            top: 0;
            right: 0;
            z-index: 1;
            margin: 0;
        }

        &__menu {
            &__list {
                padding: 0 30px;
            }

            &__item {
                flex: inherit;

                &:first-child {
                    .footer__menu__link {
                        padding-left: 0;
                    }
                    
                }

                &:last-child {
                    .footer__menu__link {
                        padding-right: 0;
                    }
                }

                &:nth-child(3) {
                    .footer__menu__link {
                        &::after {
                            display: none;
                        }
                    }
                }
            }

            &__link {
                padding: 7px 15px;

                &::after {
                    height: 35%;
                }
            }
        }
    }

}


// 640
@media screen and (max-width: $small) {

    .footer {

        &__coordonnees, &__horaires {
            text-align: center;
            align-items: center;
        }

        &__contact {
            align-items: center;
        }

        &__button {
            margin-right: 0;
        }

        &__partner {
            position: relative;
            top: auto;
            right: auto;
            margin-top: 40px;
            order: 4;
        }

        &__infos .container::after {
            height: 495px;
            width: 495px;
        }

        &__menu {
            &__list {
                padding: 0;
                justify-content: center;
            }

            &__item {
                &:nth-child(3) {
                    .footer__menu__link {
                        &::after {
                            display: block;
                        }
                    }
                }

                &:nth-child(1),&:nth-child(2), &:nth-child(4), &:nth-child(6) {
                    .footer__menu__link {
                        &::after {
                            display: none;
                        }
                    }
                }
            }
        }

    }

}