header {
    position: relative;
}

.header-top {
    position: relative;
    z-index: 10;

    &__container {
        position: relative;
        display: flex;
        align-items: stretch;
        justify-content: space-between;
    }

    .header__identity {

        &__link {
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            line-height: 0;    
            width: 390px;
            height: 146px;
            transition: none;
        }

        &__title {
            display: block;
            line-height: 0;
        }

        .logo {
            position: relative;
            z-index: 1;
            top: -15px;

            &__back {
                position: absolute;
                left: 0;
                top: -5px;
                bottom: 0;
                width: 390px;
                height: 146px;
                fill: $color-white;
            }
        }
    }
}


//======================================================================================================
// Tools
//======================================================================================================
#uci_link{
    display: none;
}

.tool {
    display: flex;
    align-items: center;
    margin: 0 20px;
    font-size: $font-size--text-small;
    font-weight: $font-weight-bold;
    color: $color-white;
    text-transform: uppercase;
    filter: drop-shadow(0px 0px 4px #000000);

    &:last-child {
        margin-right: 0;
    }

    svg {
        margin-right: 10px;
        fill: $color-white;
    }

    &:hover, &:focus {
        color: $color-white;
        text-decoration: underline;
    }
    
}

.tools {

    &__content {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    &__contact {
        svg {
            width: 18px;
            height: 12px;
        }
    }

    &__facebook {
        svg {
            width: 12px;
            height: 12px;
        }
    }

    &__translate, &__profile {
        svg {
            width: 16px;
            height: 16px;
        }
    }

    &__accessibility {
        padding: 0;
        background: $color-bg--transparent;
        border: 0;
        color: $color-white;

        &::after {
            display: none;
        }

        svg {
            width: 17px;
            height: 15px;
        }

        &:hover, &:focus {
            background: $color-bg--transparent;
        }
    }

    &__search {
        border: 0em;
        border-radius: 0px 0px 40px 40px;
        color: $color-black;
        filter: none;
        transition: background-color $duration ease-in-out, color $duration ease-in-out;

        &::after {
            top: -100%;
        }

        svg {
            width: 14px;
            height: 14px;
            fill: $color-black;
        }

        &:hover, &:focus {
            text-decoration: none;
        }

        &--disabled {
            pointer-events: none;
            opacity: .5;
        }

    }
}

.tools-view {
    position: absolute;
    top: 165px;
    width: 100%;
    z-index: 1;

    .container--tools-view {
        display: flex;
        justify-content: flex-end;

        #google_translate_element {
            display: none;
        }

        .profils {
            position: relative;
            display: none;
            width: 240px;
            height: auto;
            margin-top: 45px;
            margin-right: 60px;
            padding: 15px 20px 20px;
            background: $color-second;

            &.show-list {
                display: flex;
                flex-direction: column;
            }

            &__title {
                color: $color-white;
                text-transform: uppercase;
                margin: 20px 0 15px;
            }

            &__list {
                li {
                    a {
                        position: relative;
                        display: flex;
                        align-items: center;
                        width: 100%;
                        padding: 10px 20px 10px 0px;
                        border-top: 1px solid $color-white;
                        color: $color-white;
                        transition: all $duration;

                        &:hover {
                            padding-left: 10px;
                        }

                        &::after {
                            content: "\f344";
                            font: normal 13px/1 dashicons;
                            display: flex;
                            align-items: center;
                            position: absolute;
                            right: 0;
                            height: 100%;
                            color: $color-white;
                        }
                    }

                    &:last-child {
                        a {
                            border-bottom: 1px solid $color-white;
                        }
                    }
                }
            }
        }
    }
}

iframe.goog-te-menu-frame.skiptranslate {
    position: fixed;
    top: 230px !important;
    left: calc(100% - ((100% - 1200px) / 2) - 335px) !important;
}


//======================================================================================================
// Header Fixed
//======================================================================================================
.header {
    &--fixed {
        .header-top {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            background-color: $color-white;
            box-shadow: $shadow;

            .header__identity {
                display: flex;
                align-items: center;

                &__link {
                    width: 148px;
                    height: 33px;
                }

                .logo {
                    top: 0;
                    width: 148px;
                    height: 33px;

                    &__back {
                        display: none;
                    }
                }
            }


            &__nav {
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
                flex-direction: row-reverse;
            }
            
            .tool {
                display: none;
            }

            .tools__search {
                display: flex;
                width: 50px;
                height: 55px;
                margin: 0 0 15px 0;
                padding: 17px 15px;


                span {
                    display: none;
                }

                svg {
                    margin: 0;
                }

                &::after {
                    top: 0;
                    height: 100%;
                }

                &--disabled {
                    pointer-events: none;
                    opacity: .2;
                }
            }

            #nav-main {
                margin: 0 55px 0 0;

                .nav-main-item {
                    &:first-child {
                        &::before {
                            content: "";
                            position: absolute;
                            top: 30px;
                            width: 10px;
                            height: 10px;
                            border-radius: 50%;
                            background: #bcc337;
                            box-shadow: none;
                        }
                        .nav-main-link {
                            margin-left: 45px;

                            &::before {
                                box-shadow: none;
                            }
                        }
                    }

                    &:last-child .nav-main-link {
                        margin-right: 0;
                    }

                    .nav-main-link {
                        margin: 0 45px 0 35px;
                        font-weight: $font-weight-semibold;
                        text-shadow: none;
                        color: $color-text;

                        &::before {
                            right: -45px;
                            box-shadow: none;
                        }
                    }

                    .sub-menu {
                        top: 65px;
                    }
                }
            }
            
        }
    }
}

.admin-bar .header--fixed .header-top {
    top: 32px;
}

//======================================================================================================
// Quick Access
//======================================================================================================
.quick-access {
    position: fixed;
    top: 0;
    right: -910px;
    z-index: 15;
    display: block;
    height: 100%;
    width: 920px;
    background: $color-second;
    transition: all 0.75s;

    &__title {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        position: absolute;
        right: 820px;
        top: 50%;
        width: 230px;
        height: 50px;
        color: $color-white;
        text-transform: uppercase;
        font-weight: $font-weight-bold;
        transform: rotate(-90deg);

        span {
            margin-top: 10px;

            &::before,
            &::after {
                content: "\f343";
                font: normal 20px/1 dashicons;
                position: absolute;
                right: 30px;
                color: $color-white;
                transition: all $duration;
            }

            &::after {
                left: 30px;
                right: inherit;
            }
        }
        
        svg {
            position: absolute;
            left: 0;
            top: 0;
            z-index: -1;
            width: 100%;
            height: 100%;
            fill: $color-second;
            transform: rotate(180deg);
        }
    }

    &__container {
        padding-left: 75px;
        height: 100%;

        h2 {
            color: $color-white;
            border: 0;
            margin: 90px 0 50px;
            text-transform: uppercase;
        }

        ul {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            width: 560px;
            max-height: 560px;

            li {
                width: calc(50% - 30px);
                margin-bottom: 15px;

                a {
                    display: block;
                    width: 100%;
                    color: $color-white;
                    text-decoration: underline;

                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }
    }

    &.open {
        right: 0;

        .quick-access__title {
            span {
                &::before,
                &::after {
                    transform: rotate(180deg);
                }
            }
        }
    }
}


//======================================================================================================
// Privacy Policy Banner
//======================================================================================================
.privacy {
    position: fixed;
    bottom: 0;
    z-index: 100;
    width: 100%;
    background: $color-black--rgba;
    color: $color-white;
    transition: all $duration;

    &__container {
        display: flex;
        align-items: center;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    &__title {
        font-weight: $font-weight-bold;
        margin-bottom: 5px;
    }

    &__info {
        font-size: $font-size--text-small;
        padding-right: 20px;
    }

    &__link {
        text-decoration: underline;

        &:hover {
            color: $color-white;
            text-decoration: none;
        }
    }

    &__button {
        width: 240px;
        margin-left: 15px;
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    .header-top {
        .header {
            &__identity {
                margin-left: -50px;

                &__link {    
                    width: 300px;
                    height: 107px;
                }

                .logo {
                    width: 199px;
                    height: 45px;

                    &__back {
                        width: 300px;
                        height: 107px;
                    }
                }
            }
        }
    }

    .tools {
        &__content {
            justify-content: flex-end;
        }

        &__search {
            position: relative;
            top: -7px;
            display: flex;
            width: 50px;
            height: 55px;
            margin: 0;
            padding: 17px 15px;


            span {
                display: none;
            }

            svg {
                margin: 0;
            }

            &::after {
                top: 0;
                height: 100%;
            }
        }
    }

    iframe.goog-te-menu-frame.skiptranslate {
        position: absolute;
        left: calc(100% - (100% - 960px)/2 - 335px)!important;
        top: 230px!important;
    }

    .header--fixed {
        .header-top {
            .tools__search {
                top: 0;
            }

            .header__identity {
                margin-left: 0;
            }

            #nav-main {
                margin: 0 15px 0 0;

                .nav-main-item {
                    .nav-main-link {
                        margin: 0;
                        padding: 20px 25px;
                        font-size: $font-size--text-xmedium;
                        
                        &::before {
                            right: -5px;
                        }

                        &::after {
                            bottom: -5px;
                        }
                    }

                    &:first-child .nav-main-link {
                        padding-left: 0;
                        margin-left: 25px;

                        &::before {
                            right: -5px;
                        }
                    }

                    &:last-child .nav-main-link {
                        padding-right: 0;
                    }

                    .sub-menu {
                        top: 70px;
                    }
                }
            }
        }

        .header-top__nav {
            align-items: center;
        }
    }

}


// 960
@media screen and (max-width: $medium) {

    .header-top {
        &__container {
            align-items: flex-start;
        }

        .header {
            &__identity {
                margin-left: -45px;

                &__link {    
                    width: 270px;
                    height: 97px;
                }

                .logo {
                    width: 179px;
                    height: 40px;

                    &__back {
                        width: 270px;
                        height: 97px;
                    }
                }
            }
        }
    }

    .header-top__nav {
        display: flex;
        align-items: center;
        flex-direction: row-reverse;
        justify-content: space-between;
    }

    .tools {
        margin-left: 15px;

        .tool {
            margin: 0 15px;

            &:last-child {
                margin-right: 0;
            }

            svg {
                margin: 0;
            }

            span {
                display: none;
            }
        }

        &__contact {
            svg {
                width: 28px;
                height: 19px;
            }
        }
    
        &__facebook {
            svg {
                width: 22px;
                height: 22px;
            }
        }
    
        &__accessibility {
            svg {
                width: 24px;
                height: 21px;
            }
        }

        &__search {
            margin-left: 15px;
        }
    }

    .quick-access {
        display: none;
    }

    iframe.goog-te-menu-frame.skiptranslate {
        position: absolute;
        left: calc(100% - (100% - 640px)/2 - 335px)!important;
        top: 240px!important;
    }

    .privacy {
        &__container {
            flex-wrap: wrap;
        }

        &__button {
            margin-left: 0;
            margin-top: 20px;
        }
    }
  
}


// 640
@media screen and (max-width: $small) {

    .header-top {
        position: relative;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: -1;
            height: 50px;
            background-color: $color-second;
        }
    }

    .tools {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin-left: 0;
        background-color: $color-second;

        &__content {
            justify-content: space-around;
        }

        .tool {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 50px;
            height: 50px;
            filter: none;

            svg {
                fill: $color-black;
            }

            &:last-child {
                margin-right: 15px;
            }
        }

        &__search {
            top: auto;
            padding: 0;

            svg {
                width: 22px;
                height: 22px;
            }

            &::after {
                display: none;
            }

            &:hover, &:focus {
                background-color: transparent;
            }

            &--disabled {
                pointer-events: none;
                opacity: .2;
            }
        }
    }

    #nav-main {
        margin-top: 60px;

        .menu__nav__list {
            top: 125px;
        }
    }

    .nav-main_icon {
        .label {
            display: none;
        }

        .navicon {
            background: $color-white;

            &::before, &::after {
                background: $color-white;
            }
        }
    }

    .header-top .header__identity {
        margin-top: 55px;
    }

    iframe.goog-te-menu-frame.skiptranslate {
        position: absolute!important;
        left: calc(100% - (100% - 300px/2))!important;
        top: 280px!important;
    }

    .tools-view {
        .container--tools-view {
            .profils {
                margin-top: 80px;
                margin-right: 35px;
            }
        }
    }

}
