.post_navigation {
    // display: flex;
    // justify-content: center;
    padding: 17px 0;
    background: $color-bg--neutral;

    .return {
        a {
            position: relative;
            display: flex;
            align-items: center;
            padding: $btn-padding;
            border: none;
            border-radius: $btn-border-radius;
            text-transform: $btn-text-transform;
            font-size: $font-size--text-small;
            font-weight: $font-weight-bold;
            color: $color-text;
            cursor: pointer;
            overflow: hidden;
            transform: perspective(1px) translateZ(0);
            transition: all $duration ease-in-out;

            svg {
                position: relative;
                top: 1px;
                width: 26px;
                height: 26px;
                margin-right: 10px;
                fill: $color-text;
                transition: all $duration;
            }

            &::after {
                content: "";
                position: absolute;
                z-index: -1;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                height: 200%;
                background-color: $btn-bg--hover;
                border-radius: 100%;
                transform: scale(0);
                transition: transform $duration ease-in-out;
            }

            &:hover {
                color: $btn-color--hover;
                background-color: $btn-bg--hover;

                svg {
                    fill: $btn-color--hover;
                }
                
                &::after {
                    transform: scale(2);
                    transition: transform $duration ease-in-out;
                }
            }
        }
    }
}

// Update Upgrades 11/2023
// for all buttons navigations
.navigation_link {
    column-gap: 5px;
}

.post_navigation {
    &--container {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        // Si les liens sont cachés, mettre ce style :
        // justify-content: space-evenly;
        width: 100%;
        gap: 30px;
    }

    .return {
        a {
            align-items: center;
        }
    }

    .prev, .next {
      // A adapter en fonction de la width du bouton précédent
      min-width: 172px;

        .navigation_link {
            position: relative;
            display: flex;
            align-items: center;
            padding: $btn-padding;
            border: none;
            border-radius: $btn-border-radius;
            text-transform: $btn-text-transform;
            font-size: $font-size--text-small;
            font-weight: $font-weight-bold;
            color: $color-text;
            cursor: pointer;
            overflow: hidden;
            transform: perspective(1px) translateZ(0);
            transition: all $duration ease-in-out;

            // Recup style ~ ligne 9
            a {
                display: flex;
                align-items: center;
            }

            &::after {
                content: "";
                position: absolute;
                z-index: -1;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                height: 200%;
                background-color: $btn-bg--hover;
                border-radius: 100%;
                transform: scale(0);
                transition: transform $duration ease-in-out;
            }
        }
    }

    .prev {
        .navigation_link {
            a {
                &:before {
                    content: "";
                    display: inline-block;
                    width: 26px;
                    height: 26px;
                    background-color: $color-gray;
                    mask-image: url( $urlShort + '/ic-nav-arrow-left.svg');
                    mask-repeat: no-repeat;
                    transition: background-color $duration ease-in-out;
                    margin-right: 5px;
                }
            }
        }
    }

    .next {
        .navigation_link {
          // Si il y a un background sur le navigation_link mettre sur .next
             display: flex;
            justify-content: flex-end;

          a {
                &:after {
                    content: "";
                    display: inline-block;
                    width: 26px;
                    height: 26px;
                    background-color: $color-gray;
                    mask-image: url( $urlShort + '/ic-nav-arrow-right.svg');
                    mask-repeat: no-repeat;
                    transition: background-color $duration ease-in-out;
                    margin-left: 5px;
                }
            }
        }
    }

    .prev, .next {
        &:hover, &:focus, &:focus-within {

            .navigation_link {
                color: $btn-color--hover;
                background-color: $btn-bg--hover;

                svg {
                    fill: $btn-color--hover;
                }

                a {
                    color: $btn-color--hover;

                    &:before, &:after {
                        background-color: $btn-color--hover;
                    }
                }
                    
                &::after {
                    transform: scale(2);
                    transition: transform $duration ease-in-out;
                }
            }
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================


// 960
@media screen and (max-width: $medium) {

    .post_navigation {
        
        .next, .prev {
            min-width: 152px;
        }

        .next .navigation_link, 
        .prev .navigation_link, 
        .return a {
            padding: 10px 20px;
        }
    }

}


// 640
@media screen and (max-width: $small) {

    .post_navigation {
        &--container {
            gap: 30px 15px;
        }

        .return {
            display: flex;
            justify-content: center;
            order: 1;
            flex: 1 1 100%;
        }

        .next, .prev {
            min-width: auto;
        }
    }
}
