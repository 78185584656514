.proposition-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 120px;
    background: $color-bg--neutral;
    border-radius: $border-radius;

    svg {
        height: 70px;
        max-width: 100px;
        margin: 25px 45px;
    }

    .proposition-content {
        display: flex;
        align-items: center;

        &__text {
            max-width: 730px;
            padding: 20px 0;

            h4 {
                margin: 0 0 10px;
            } 

            p {
                margin: 0;
                color: $color-text;
                font-size: $font-size--text-small;
            }
        }
    }

    .button {
        margin: 30px 40px;

        svg {
            width: 26px;
            height: 26px;
            margin: 0 5px 0 0;
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 960 // à revoir en structure html
@media screen and (max-width: $medium) {

    .proposition-wrapper {
        position: relative;
        flex-wrap: wrap;
        
        .proposition-content {
            width: 100%;

            svg {
                max-width: 150px;
            }
            
            &__text {
                width: 380px;
                padding: 20px 20px 100px 0;
            }
        }

        .button {
            position: absolute;
            left: 240px;
            bottom: 20px;
            margin: 0;
        }
    }

}


// 640
@media screen and (max-width: $small) {

    .proposition-wrapper {
        .proposition-content {
            display: flex;
            align-items: baseline;
            flex-direction: column;
            padding: 20px;

            svg {
                max-width: 100px;
            }

            &__text {
                width: 100%;
                padding: 20px 0;
            }
        }

        svg {
            margin: 0;
        }

        .button {
            position: relative;
            left: auto;
            bottom: auto;
            margin: -20px 0 20px 20px;
        }
    }

}
