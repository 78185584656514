.breadcrumb {
    background-color: $color-main;

    ul  {
        display: block;
        min-height: 60px;
        padding: 18px 0;
    }

    li {
        position: relative;
        display: inline;
        padding: 0 15px 0 0;
        color: $color-text;
        line-height: 1.75;

        &:first-child { 
            padding-left: 0;
        }

        &:last-child {
            &:after {
                display: none;
            }
        }

        a {
            &:hover, &:focus {
                text-decoration: underline;
            }
        }
    }

    svg {
        position: relative;
        top: 5px;
        display: inline;
        margin-right: 15px;
        width: 20px;
        height: 20px;        
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 640
@media screen and (max-width: $small) {

    .breadcrumb {
        position: relative;

        li {
            margin-right: 0;
            padding: 0;

            svg {
                margin-right: 0;
            }
        } 
    }

}
