.cover {
    position: relative;
    margin-top: -150px;

    &__image-wrapper {
        position: relative;
        width: 100%;
        height: 600px;
        background: $color-bg--image;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: top;
        }

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: linear-gradient(180deg, rgba(0, 0, 0, 0.67) 0%, rgba(0, 0, 0, 0) 53.19%);
        }
    }

    &__informations {
        background: $color-extralight;
        padding: 30px 0;
    }

    &__title {
        position: relative;
        display: inline;
        margin: 0 0 10px;
        padding-right: 10px;
        color: $color-text;
        font-weight: $font-weight-light;
    
        &::after {
            content: '';
            position: absolute;
            width: 38px;
            height: 60px;
            margin-left: 15px;
            background: url(/wp-content/themes/chanverrie/assets/src/images/sprite-images/decoration/forme_h1.svg) no-repeat center;
        }
    }

    &__lead-wrapper {
        p {
            margin: 20px 0 0;
            font-weight: $font-weight;
            font-size: $font-size--5;
        }
    }

    &__intro {
        color: $color-text;
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    .cover {
        &__image-wrapper {
            height: 540px;
        }
    }

}


// 960
@media screen and (max-width: $medium) {

    .cover {
        &__image-wrapper {
            height: 435px;
        }
    }

}


// 640
@media screen and (max-width: $small) {

    .cover {
        &__lead-wrapper {
            p {
                font-weight: $font-weight;
            }
        }
        
        &__image-wrapper {
            height: 350px;
        }

        &__title::after {
            height: 45px;
        }
    }

}
