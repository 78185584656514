.home {

    //======================================================================================================
    // Style Home
    //======================================================================================================
    &__title {
        margin: 0 0 40px;
        font-size: 2.75rem;
        font-weight: $font-weight;
        text-transform: uppercase;

        &::after {
            position: absolute;
            right: -55px;
            width: 38px;
            top: 0;
            bottom: 0;
            height: 100%;
            margin: 0;
        }

        &--news {
            width: 100%;

            &::after {
                right: auto;
                left: 220px;
            }
        }

        &--green {
            &::after {
                background-image: url(/wp-content/themes/chanverrie/assets/src/images/sprite-images/decoration/gouttes_green.svg)
            }
        }

        &--white {
            &::after {
                background-image: url(/wp-content/themes/chanverrie/assets/src/images/sprite-images/decoration/gouttes_white.svg);;
            }
        }


    }

    &__button {
        margin-top: 30px;
        background-color: $color-second;
        border-color: $color-second;
        color: $color-black;
        padding: 12px 20px;

        svg {
            fill: $color-black;
            background-color: $color-white;
            margin-right: 10px;
            border-radius: $border-radius--round;
            transition: background-color $duration ease-in-out;
        }

        &::after {
            right: inherit;
            bottom: inherit;
            top: 15px;
            left: 22px;
            width: 20px;
            height: 20px;
            transform: scale(1);
            transition: all $duration ease-out;
            background-color: $color-dark;
        }

        &:hover, &:focus {
            svg {
                background-color: $color-second;
                border-color: $color-second;
                fill: $color-black;
                transition: all $duration ease-in-out;
            }

            &::after {
                top: -100%;
                left: -40px;
                height: 200px;
                width: 200px;
                transition: all $duration ease-out;
            }
        }

        &--light {
            background-color: $color-fourth;

            &:hover, &:focus {
                svg {
                    background-color: $color-fourth;
                }
            }
        }
    }

    .container {
        &--flex {
            display: flex;
            align-items: stretch;
            flex-wrap: wrap;
        }

        &--postsnews {
            position: relative;
            padding: 70px 0;

            &::after {
                content: '';
                position: absolute;
                top: -150px;
                right: -110px;
                z-index: -1;
                width: 780px;
                height: 780px;
                background: url(/wp-content/themes/chanverrie/assets/src/images/motif-png.png) repeat center;
                border-radius: $border-radius--round;
            }
        }
    }

    &__overflow {
        overflow: hidden;
    }

    &__overflow--x {
        overflow-x: hidden;
    }


    //======================================================================================================
    // SlideShow
    //======================================================================================================
    .home-slideshow {
        position: relative;
        margin-top: -150px;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: linear-gradient(180deg, rgba(0, 0, 0, 0.67) 0%, rgba(0, 0, 0, 0) 53.19%);
            pointer-events: none;
            z-index: 2;
        }

        .swiper-container {
            height: 675px;
            width: 100%;

            .swiper-slide {
                img {
                    position: absolute;
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                    object-position: top;
                }
            }

            .container--pagination {
                position: relative;
            }

            .swiper-pagination {
                position: absolute;
                right: 0;
                width: auto;
                height: auto;
                bottom: 40px;
                z-index: 2;
                pointer-events: auto;
                filter: drop-shadow(0px 0px 20px #000000);
  
                .swiper-pagination-bullet {
                    position: relative;
                    margin: 0 0 0 30px;
                    width: 20px;
                    height: 20px;
                    background: $color-white;
                    border-radius: 100px;
                    margin-left: 30px;
                    transition: $duration ease-in-out;
                    transform-origin: 50% 50%;
                    opacity: 1;
                    filter: drop-shadow(0px 0px 20px #000000);
                }

                .swiper-pagination-bullet-active {
                    width: 14px;
                    height: 14px;
                    background: $color-third;
                }
            }
        }

        .slideshow {
            display: inherit;
            position: relative;
            width: 100%;
            height: 100%;

            .container--slideshow {
                position: relative;
                height: 100%;
            }

            &__content {
                display: flex;
                flex-direction: column;
                box-sizing: border-box;
                position: absolute;
                right: 15px;
                bottom: 90px;
                width: 500px;
                height: auto;
                padding: 20px;
                background: $color-white--rgba;
                color: $color-text;
                border-left: 15px solid $color-third;
            }

            &__title {
                margin-bottom: 0;
                font-family: $font-family--heading;
                font-size: $font-size--4;
                font-weight: $font-weight-semibold;
            }

            &__description {
                margin-top: 15px;
            }
        }

        .video-container {
            height: 675px;
            width: 100%;

            .slideshow {
                display: inherit;
                position: relative;
                width: 100%;
                height: 100%;

                video,
                img {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: top;
                }

                img {
                    display: none;
                }
            }
        }
    }


    //======================================================================================================
    // Access
    //======================================================================================================
    .access {
        position: relative;
        padding: 40px 0;
        background-color: $color-main;

        &__list {
            display: flex;
            align-items: stretch;

            li {
                flex: 1 1 0;
            }
        }

        &__link {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            color: $color-text;

            &:hover, &:focus {
                color: $color-black;

                .access__icon {
                    border-color: $color-second;
                    transition: all $duration ease-in-out;

                    &__color {
                        fill: $color-second;
                        transition: fill $duration ease-in-out;
                    }

                    &__stroke {
                        stroke: $color-second;
                        transition: stroke $duration ease-in-out;
                    }
                }
            }
        }

        &__icon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 90px;
            height: 90px;
            background-color: $color-white;
            border: 5px solid $color-bg--transparent;
            border-radius: $border-radius--round;
            transition: all $duration ease-in-out;

            &__color {
                transition: all $duration ease-in-out;
            }
        }

        &__title {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 10px 0;
            font-size: $font-size--text-xmedium;
            font-weight: $font-weight-bold;
        }

    }

    
    //======================================================================================================
    // News (posts)
    //======================================================================================================
    .news {
        position: relative;
        width: 770px;
        margin-right: 30px;

        &__container {
            position: relative;
        }

        &__list {
            display: flex;
            align-items: stretch;
            justify-content: space-between;
        }

        &__link {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: stretch;
            justify-content: stretch;
            overflow: hidden;
            width: 368px;
            height: 100%;
            background-color: $color-white;
            border-radius: $border-radius;
            color: $color-text;
            box-shadow: $shadow;
            transition: all $duration ease-in-out;

            &:hover, &:focus {
                background-color: $color-bg--neutral;
                color: $color-text;
                box-shadow: none;
                transition: all $duration ease-in-out;

                img {
                    transform: scale(1.1);
                    transition: transform ease-in-out $duration;
                }
            }

        }

        &__content {
            position: relative;
            overflow: hidden;
            line-height: 0;
            height: 270px;

            &::after {
                content: '';
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                height: 15px;
                background: url(/wp-content/themes/chanverrie/assets/src/images/sprite-images/decoration/courbe_actus.svg) no-repeat center;
                z-index: 1;
            }

            &--notimg {
                background: url(/wp-content/themes/noyau/assets/images/icon-post.png) no-repeat $color-bg--image;
                background-position: center;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: top center;
                transform: scale(1);
                transition: transform ease-in-out $duration;
            }
        }

        &__infos {    
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            box-sizing: border-box;
            height: 45%;
            padding: 25px 20px;
            text-align: left;

            &__title {
                margin: 0;
                font-size: $font-size--3;
            }

            &__introduction {
                margin-top: 10px;
            }

            &__link {
                display: flex;
                align-items: center;
                margin-top: 15px;
                font-size: $font-size--text-small;
                font-weight: $font-weight-bold;
                text-transform: uppercase;

                svg {
                    width: 14px;
                    height: 14px;
                    margin-right: 10px;
                }
            }
        }
  
    }


    //======================================================================================================
    // Events
    //======================================================================================================
    .events {
        position: relative;
        width: 370px;

        &__container {
            position: relative;
        }

        &__list {
            display: flex;
            flex-wrap: wrap;
        }

        &__item {
            position: relative;
            display: flex;
            height: 145px;
            width: 100%;
            margin-bottom: 30px;
            box-shadow: $shadow;
            background-color: $color-white;
            border-radius: $border-radius;
            overflow: hidden;
            transition: all ease-in-out $duration;

            &:hover, &:focus {
                color: $color-text;
                box-shadow: none;
                transition: all ease-in-out $duration;

                &::before {
                    width: 150%;
                    transition: all ease-in-out $duration;
                }
            }

            &::before {
                content: '';
                position: absolute;
                top: -10px;
                left: 0px;
                bottom: -10px;
                width: 60px;
                background-color: $color-fourth;
                border-radius: 0 50% 50% 0;
                z-index: 0;
                transition: all ease-in-out $duration;
            }

            &::after {
                content: '';
                position: absolute;
                right: 9px;
                bottom: 9px;
                width: 16px;
                height: 16px;
                background: url(/wp-content/themes/chanverrie/assets/src/images/sprite-images/home/actus_agenda_+.svg) no-repeat center; 
            }

            &:last-child {
                margin: 0;
            }
        }

        &__link {
            position: relative;
            z-index: 1;
            display: flex;
            align-items: center;
            padding: 15px 20px;
        }

        &__date {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            min-height: 70px;
            max-height: 110px;
            padding: 5px 0;
            background-color: $color-second;
            border-radius: $border-radius;
            font-family: $font-family--heading;
            color: $color-black;
            box-sizing: border-box;

            p {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                width: 70px;
            }

            &__day {
                font-size: $font-size--3;
                line-height: 1.1;
                font-weight: $font-weight-bold;
            }

            &__month {
                margin-top: 3px;
                font-size: $font-size--text-small;
                font-weight: $font-weight-semibold;
                line-height: 1.1;
                text-transform: uppercase;
            }

            svg {
                position: relative;
                width: 15px;
                height: 15px;
                fill: $color-black;
            }
        }

        &__info {
            &__title {
                margin: 0 0 0 30px;
                font-size: $font-size--4;
            }
        }

    }


    //======================================================================================================
    // Contacts
    //======================================================================================================
    .contacts {
        position: relative;
        width: 46%;
        padding: 70px 130px 70px 0;
        background-color: $color-bg--neutral;

        &::before {
            content: '';
            position: absolute;
            left: -50vw;
            top: 0;
            bottom: 0;
            width: 50vw;
            background-color: $color-bg--neutral;
            z-index: -1;
        }

        &__desc {
            font-size: $font-size--text-xmedium;
            font-weight: $font-weight-bold;
        }

        &__list {
            margin: 20px 0;
            padding: 20px 30px 20px 45px;
            background-color: $color-white;
        }

        &__link {
            display: inline-block;
            color: $color-text;
            font-weight: $font-weight-bold;
            background:
                radial-gradient(farthest-side at bottom, $color-main 98%, transparent) bottom/calc(2*var(--d, 3px)) var(--d, 0) no-repeat,
                linear-gradient($color-main 0 0) bottom /100% 0px no-repeat;
            transition: $duration;

            &:hover,
            &:active,
            &:focus {
                --d: 150%;
                color: $color-link--hover;
            }
        }

        &__item {
            padding: 5px 0;

            &::before {
                content: "•";
                position: relative;
                height: 0;
                top: -2px;
                left: -18px;
                display: flex;
                font-size: 1.2rem;
                color: $color-main;
            }

            &--external {
                &::before {
                    color: $color-second;
                }

                .contacts__link {
                    background:
                        radial-gradient(farthest-side at bottom, $color-second 98%, transparent) bottom/calc(2*var(--d, 3px)) var(--d, 0) no-repeat,
                        linear-gradient($color-second 0 0) bottom /100% 0px no-repeat;

                    &:hover,
                    &:active,
                    &:focus {
                        --d: 150%;

                        svg {
                            fill: $color-black;
                            transition: fill $duration ease-in-out;
                        }
                    }

                    span {
                        display: flex;
                        align-items: center;
    
                        svg {
                            width: 20px;
                            height: 20px;
                            margin-left: 5px;
                            fill: $color-text;
                            transition: fill $duration ease-in-out;
                        }
                    }
                }
            }
        }

        .home__button {
            margin-top: 20px;
            background-color: $color-main;

            &:hover, &:focus {
                background-color: $color-dark;

                svg {
                    background-color: $color-main;
                }
            }
        }
    }

    //======================================================================================================
    // Kiosque (docs)
    //======================================================================================================
    .kiosque {
        position: relative;
        width: 54%;
        padding: 70px 0 70px 70px;
        background-color: $color-third;

        &::before {
            content: '';
            position: absolute;
            left: -211px;
            top: 0;
            bottom: 0;
            width: 226px;
            background-image: url(/wp-content/themes/chanverrie/assets/src/images/sprite-images/decoration/courbe_kiosque.svg);
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
        }

        &::after {
            content: '';
            position: absolute;
            right: -50vw;
            top: 0;
            bottom: 0;
            width: 50vw;
            background-color: $color-third;
            z-index: -1;
        }

        &__decoration {
            display: block;
            position: absolute;
            right: -186px;
            bottom: -89px;
            width: 604px;
            height: 604px;
            background-image: url(/wp-content/themes/chanverrie/assets/src/images/sprite-images/decoration/filigrane_kiosque.svg);
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            z-index: 0;
            pointer-events: none;    
        }
        
        &__container {
            position: relative;
        }

        &__list {
            position: relative;
            z-index: 1;
        }

        &__item {
            position: relative;
        }

        &__image {
            width: 300px;
            height: 428px;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: top;
            }

            &--no-image {
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: $color-bg--image;

                svg {
                    width: 120px;
                }
            }
        }

        &__content {
            position: absolute;
            bottom: 110px;
            right: 0;
            width: 300px;
            height: auto;
            padding: 30px;
            background-color: $color-white;
            border-left: 15px solid $color-second;
            box-shadow: $shadow;
        }

        &__title {
            margin: 0 0 20px 0;
            font-size: $font-size--4;
            font-weight: $font-weight-semibold;
        }

        &__info {
            margin-bottom: 20px;
        }
        
        &__filesize {
            text-transform: uppercase;
        }

        .home__buttons {
            position: absolute;
            bottom: 30px;
            right: 0;
            z-index: 1;

            .home__button {
                margin: 0 0 0 30px;
            }
        }

        &__btn {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            color: $color-text;
            font-size: $font-size--text-small;
            font-weight: $font-weight-bold;
            text-transform: uppercase;
            text-decoration: none;
            transition: all $duration ease-out;
            background:
                radial-gradient(farthest-side at bottom, $color-second 98%, transparent) bottom/calc(2*var(--d, 3px)) var(--d, 0) no-repeat,
                linear-gradient($color-second 0 0) bottom /100% 0px no-repeat;

            svg {
                position: relative;
                width: 30px;
                height: 30px;
                fill: $color-text;
                margin-right: 10px;
                transition: all $duration ease-out;
            }

            &:hover, &:focus {
                color: $color-black;
                --d: 150%;
                transition: all $duration ease-out;

                svg {
                    fill: $color-black;
                    transition: all $duration ease-out;
                }
            }
        }
    }


    //======================================================================================================
    // Social Wall
    //======================================================================================================
    .social-wall {
        position: relative;
        padding: 80px 0;

        .ff-stream {
            background: transparent;
            background-color: transparent;
            padding: 0;
            margin: 0 -20px;
            overflow: hidden;

            .ff-header, .ff-loadmore-wrapper {
                display: none;
            }

            .ff-stream-wrapper {
                z-index: 2;
                margin-top: 10px;
            }

            .ff-item {
                margin-bottom: 20px;
                filter: drop-shadow(0px 0px 1px rgba(27, 35, 54, 0.08)) drop-shadow(1px 1px 10px rgba(27, 35, 54, 0.08));
            }
        }

        .ff-upic-round .ff-img-holder:first-child, 
        .ff-upic-round .ff-img-holder:first-child img, 
        .shuffle--animatein .in .ff-moderation-wrapper, 
        .shuffle--animatein .in .picture-item,
        .ff-upic-round .picture-item__inner {
            border-radius: 0;
        }
    }


}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {
    .home {

        // Homeslideshow
        .home-slideshow {
            .swiper-container, .video-container {
                height: 540px;
            }

            .slideshow__content {
                width: 430px;
            }
        }

        // Access 
        .access__title {
            font-size: 0.938rem;
        }

        .news {
            width: 620px;
            margin-right: 20px;

            &__link {
                width: 300px;
            }

            &__content {
                height: 217px;
            }
        }

        .events {
            width: 300px;

            &__item {
                height: 141px;
                margin-bottom: 20px;
            }
        }

        &__buttons {
            display: flex;
            justify-content: space-between;
        }

        &__button {
            margin-right: 0;
        }

        .contacts {
            width: 49%;
            padding: 70px 70px 70px 0;

            &__list {
                padding: 17px 20px 17px 40px;
            }

            &__item {
                padding: 3px 0;
            }
        }

        .kiosque {
            width: 51%;
            padding: 70px 0 70px 15px;

            &::before {
                left: -210px;
            }

            &__decoration {
                right: -135px;
                bottom: -30px;
                width: 465px;
                height: 465px;
            }

            &__image {
                width: 235px;
                height: 334px;
            }

            .home__buttons {
                position: relative;
                bottom: auto;
                right: auto;  
                margin-top: 33px;

                .home__button {
                    margin: 0;
                }
            }

            &__content {
                bottom: 60px;
                padding: 25px;
            }
        }

    }
    
}


// 960
@media screen and (max-width: $medium) {


    .home {
        
        &__buttons {
            justify-content: flex-start;
        }

        &__button {
            margin-right: 20px;
        }

        // Homeslideshow
        .home-slideshow {
            position: relative;

            .video-container {
                .slideshow {
                    video {
                        display: none;
                    }
                    img {
                        display: block;
                    }
                }
            }

            .swiper-container, .video-container {
                height: 568px;
            }

            .swiper-container .swiper-pagination {
                bottom: 20px;
                left: 0;
                right: auto;

                .swiper-pagination-bullet {
                    margin: 0 30px 0 0;
                }
            }

            .swiper-slide {
                overflow: hidden;
            }

            .slideshow__content {
                bottom: 66px;
                left: 15px;
                right: 15px;
                width: auto;
                background-color: $color-white;
                border-left: 0;
                border-top: 15px solid $color-third;
            }
        }

        // Access 
        .access {
            &__list {
                flex-wrap: wrap;
                justify-content: space-between;

                li {
                    flex: inherit;
                    width: 170px;

                    &:first-child, &:nth-child(2), &:nth-child(3) {
                        margin-bottom: 60px;
                    }
                }
            }

            &__title {
                font-size: $font-size--text-xmedium;
            }
        }

        // News Events
        .container--postsnews::after {
            top: -60px;
            right: -410px;
            width: 890px;
            height: 890px;
        }

        .news, .events {
            width: 100%;
            margin: 0;
        }

        .news {
            margin-bottom: 40px;

            &__link {
                width: 300px;
            }

            &__content {
                height: 217px;
            }
        }

        .events {
            &__item {
                width: 48%;

                &:first-child {
                    margin-right: 20px;
                }
            }
        }


        // Contacts Kiosque
        .contacts, .kiosque {
            width: 100%;
            margin: 0;
            padding: 70px 0;
        }

        .contacts {
            padding-bottom: 130px;

            &::before {
                left: -50vw;
                right: -50vw;
                width: auto;
            }

            &__container {
                position: relative;
                z-index: 2;
            }
        }

        .kiosque {
            &::before {
                left: 0;
                width: 100%;
                transform: rotate(90deg);
                top: -660px;
                height: 1020px;
            }

            &::after {
                left: -50vw;
                right: -50vw;
                width: auto;
            }

            &__container {
                position: relative;
                z-index: 2;
                width: 90%;
            }

            &__decoration {
                display: none;
            }

            &__image {
                width: 300px;
                height: 428px;
            }

            .home__buttons {
                position: absolute;
                bottom: 30px;
                right: 0;
                margin-top: 0;
            }

            &__content {
                bottom: 110px;
            }
        }

        .social-wall {
            .ff-stream {
                max-height: 630px;
            }
        }

    }

}


// 640
@media screen and (max-width: $small) {

    .home { 

        &__title {
            margin-bottom: 30px;
            text-align: center;

            &--news {
                padding-right: 50px;

                &::after {
                    right: auto;
                    left: 240px;
                }
            }
            
        }
        
        &__buttons {
            display: flex;
            justify-content: center;
        }

        &__button {
            margin: 30px 0 0 0;
        }

        .home-slideshow {
            margin-top: -152px;

            .swiper-container, .video-container {
                height: 506px;
            }
        }

        // Access 
        .access {
            &__list li {
                width: 140px;
            }
            
            &__title {
                font-size: 0.938rem;
            }
        }

        .container--postsnews::after {
            top: 110px;
            right: -960px;
            width: 1390px;
            height: 1390px;
        }

        .news {
            &__list {
                flex-wrap: wrap;
            }

            &__item {
                &:first-child {
                    margin-bottom: 20px;
                }
            }

            &__buttons {
                display: flex;
                justify-content: center;
            }
        }

        .events {
            &__item {
                width: 100%;

                &:first-child {
                    margin-right: 0;
                }
            }

            .home__buttons {
                .home__button:first-child {
                    margin-right: 8px;
                }
            }

        }

        .contacts {
            .home__buttons {
                justify-content: flex-start;
            }

            .home__title {
                display: flex;
                align-items: center;
                justify-content: center;

                &::after {
                    position: relative;
                    height: 50px;
                    top: auto;
                    left: auto;
                    right: auto;
                    bottom: auto;
                    margin-left: 15px;
                }
            }
        }

        .kiosque {
            &::before {
                top: -658px;
            }

            &__container {
                width: 100%;
            }

            &__item {
                display: flex;
                justify-content: center;
            }

            &__content {
                bottom: -150px;
                left: auto;
                right: auto;
                width: 260px;
                height: auto;
                padding: 25px;
            }

            .home__buttons {
                position: relative;
                bottom: auto;
                right: auto;
                margin-top: 190px;
                justify-content: center;
            }

            .home__title {
                display: flex;
                align-items: center;
                justify-content: center;

                &::after {
                    position: relative;
                    height: 50px;
                    top: auto;
                    left: auto;
                    right: auto;
                    bottom: auto;
                    margin-left: 15px;
                }
            }
        }

        .social-wall {

            .home__title {
                text-align: left;
            }

            .home__title:after {
                right: 40px;
                width: 38px;
                height: 44px;
            }

            .ff-stream {
                max-height: 1260px;
            }
        }

    }

}
